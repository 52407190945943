<template>
  <el-row>
    <el-col :span="24">
      <div class="zone">
        <!-- <div class="ad"><img :src="imgUrl" alt="图片" /></div> -->
        <AdHeader :titmsg="titmsg" :arr="TabArr"></AdHeader>
        <GoodsList v-if="Health_typeList" :goodlist="Health_typeList"></GoodsList>
        <GoodsList v-if="OTCList" :goodlist="OTCList"></GoodsList>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="less" scoped>
@import "~style/index.less";

.zone {
  padding-bottom: 30px;
  overflow: hidden;
  .ad {
    padding-bottom: 30px;
    img {
      width: 100%;
    }
  }
  ul {
    width: 228px;
    float: left;
    li {
      height: 292px;
      margin-top: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .shopInfo {
    float: left;
    width: calc(100% - 228px);
    .item {
      margin-right: 0px !important;
      margin-left: 15px;
    }
    .item:nth-child(4n + 0) {
      margin-right: 0px;
    }
  }
}
</style>

<script>
import { SearchProducts } from "api/product.js";
const AdHeader = () => import("components/index/AdHeader.vue");
const GoodsList = () => import("components/index/GoodsList.vue");
import Qs from "qs";
import Ad from "assets/hot.png";
export default {
  name: "ShopArea",
  data() {
    return {
      ListFlag: false,
      OtcListFlag: false,
      titmsg: "",
      currentIndex: 0,
      imgUrl: Ad,
      Health_typeList: "",
      OTCList: "",
      TabArr: [],
    };
  },
  props: {
    productType: {
      type: String,
    },
  },
  created() {
    this.getShopArea(true);
  },
  methods: {
    getShopArea() {
      if (this.productType == "Health_type") {
        this.titmsg = "医保用药";
        const parmesData = {
          //默认全国甲类医保选中
          medicalInsuranceScope: 0, // "medicalInsuranceScope", value = "医保范围:0->全国 1->部分"
          medicalInsuranceType: 1, //"medicalInsuranceType", value = "医保类别:0->非医保 1->甲类医保 2->乙类医保"
        };
        SearchProducts(parmesData).then((res) => {
          this.Health_typeList=Qs.parse(Qs.stringify(res.data)).data.records;
          this.ListFlag = true;
          this.TabArr = [
            {
              name: "全国甲类",
              type: {
                medicalInsuranceScope: 0,
                medicalInsuranceType: 1,
              },
            },
            {
              name: "全国乙类",
              type: {
                medicalInsuranceScope: 0,
                medicalInsuranceType: 2,
              },
            },
          ];
        });
      } else if (this.productType == "OTC") {
        this.titmsg = "非处方药";
        const parmesData = {
          //默认非处方药 甲级
          otcType: 1, //"otcType", value = "OTC标志 0->处方药 1->非处方药"
          drugCategory: 0, //"drugCategory", value = "非处方药类型：0-> 甲级 1->乙级"
        };
        SearchProducts(parmesData).then((res) => {
          this.OTCList = Qs.parse(Qs.stringify(res.data)).data.records;
          this.OtcListFlag = true;
          this.TabArr = [
            {
              name: "OTC甲类",
              type: {
                otcType: 1, //"otcType", value = "OTC标志 0->处方药 1->非处方药"
                drugCategory: 0, //"drugCategory", value = "非处方药类型：0-> 甲级 1->乙级"
              },
            },
            {
              name: "OTC乙类",
              type: {
                otcType: 1,
                drugCategory: 1,
              },
            },
          ];
        });
      }
    },
  },
  components: {
    AdHeader,
    GoodsList,
  },
};
</script>
